<script>
import axios from "axios";
import Swal from "sweetalert2";
/**
 * Preloader component
 */
export default {
  data() {
    return {
      access_token: this.$route.query.access_token,
      kbli: this.$route.query.kbli,
    };
  },
  props: {
    loading: Boolean,
  },
  mounted() {
    let self = this;
    // console.log(self.access_token);
    let config_user = {
      method: "get",
      url: process.env.VUE_APP_BACKEND_URL_VERSION + "portal/fetch-user",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + self.access_token,
      },
    };
    axios(config_user)
      .then(function (response) {
        if (response.data.meta.code == 200) {
          var token = self.access_token;
          localStorage.access_token = token;
          localStorage.session = JSON.stringify(response.data.data);
          localStorage.session_nama = response.data.data.name;
          localStorage.session_nib = response.data.data.nib;
          localStorage.kbli = self.kbli;
          localStorage.reload_already = "no";
          localStorage.dateLogin = Date.now();
          localStorage.roles = response.data.data.role_slug;
          console.log(response.data.data.role_slug);
          if (response.data.data.role_slug == "pemohon") {
            self.$router.push({ name: "pemohon-dashboard" });
            // console.log("pemohon-dashboard");
          } else {
            self.$router.push({ name: "dashboard-admin" });
            // console.log("dashboard");
          }
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Terjadi Kesalahan, Coba lagi nanti",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};
</script>

<template>
  <div :class="{ 'is-loading': loading }">
    <div class="preloader-component">
      <div
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
        "
      >
        <img src="@/assets/images/logo-kementan.png" alt height="100" />
        <br />
        <br />
        <h3 class="ms-1"><b>Kementerian Pertanian</b></h3>
        <br />
        <div class="spinner-chase">
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
        </div>
        <br />
        <h4><b>Mohon Tunggu Sebentar</b></h4>
      </div>
    </div>
    <slot />
  </div>
</template>
